import './App.css';
import { useMemo } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ErrorBoundary } from 'react-error-boundary';
import {
  CssBaseline,
  Button,
  Toolbar,
  AppBar,
  Box,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Divider,
  Typography,
  Link as MLink,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { blue, grey, indigo } from "@mui/material/colors"
import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu as MenuIcon,
  Apps as ViewerIcon,
  TableChart as RangeGroupsIcon,
  FitnessCenter as TrainerIcon,
  Brightness7 as DarkModeIcon,
  Brightness6 as LightModeIcon,
  LockOpen as LoginIcon,
  ExitToApp as LogoutIcon
} from '@mui/icons-material';
import Viewer from "./pages/Viewer";
import Trainer from './pages/Trainer';
import RangeGroups from "./pages/RangeGroups";
import ErrorFallback from './components/ErrorFallback';
import Loading from './components/Loading';
import { useState } from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider, Alert } from "@mui/material";
import { useDarkMode } from './hooks';

const DARK_MODE_BG = "#0a1929"

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    minHeight: "calc(100vh - 120px)",
  },
  logo: {
    marginRight: 10,
  },
  logoContainer: {
    flexGrow: 1
  },
  appBar: {
    paddingTop: 4,
    minHeight: 68,
    boxShadow: "none",
    background: theme.palette.mode === "light" ? indigo[500] : DARK_MODE_BG,
    borderBottom: `1px solid ${theme.palette.mode === "light" ? "none" : blue[700]}`,
  },
  list: {
    width: 250,
  },
  footer: {
    textAlign: "center",
    marginTop: 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  }
}));

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: indigo,
          divider: indigo[200],
        }
      : {
          // palette values for dark mode
          primary: blue,
          divider: blue[700],
          background: {
            default: DARK_MODE_BG,
            paper: DARK_MODE_BG,
          },
          text: {
            primary: '#fff',
            secondary: grey[500],
          },
        }),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1130,
      lg: 1280,
      xl: 1920,
    },
  },
});

function ThemedApp () {
  const [darkModeEnabled, setDarkModeEnabled] = useDarkMode();
  const mode = darkModeEnabled ? "dark" : "light";
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App darkModeEnabled={darkModeEnabled} setDarkModeEnabled={setDarkModeEnabled}/>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function App({darkModeEnabled, setDarkModeEnabled}) {
  const classes = useStyles();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const toggleDarkMode = () => setDarkModeEnabled(!darkModeEnabled);

  return (
    <>
      {
        isLoading 
          ? <Loading/>
          : <Router>
              <div className={classes.root}>
                <CssBaseline />
                <Navigation onToggleDarkMode={toggleDarkMode} darkModeEnabled={darkModeEnabled}/>
                <main className={classes.content}>
    
                  {
                    isAuthenticated
                    ? <Routes/>
                    : <Alert
                        severity="warning"
                        action={
                          <Button color="inherit" size="small" onClick={loginWithRedirect}>
                            Login
                          </Button>
                        }
                      >
                        Please login to use the viewer
                    </Alert>
                  }
                </main>
                <Box component="footer" className={classes.footer}>
                  <Divider/>
                  <Box m={1} >
                    <Typography variant="caption">
                      Designed and developed by <MLink href="mailto:rapidpokertools@gmail.com" underline="hover">Rapid Poker Tools</MLink>
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Router>
      }
    </>
  );
}

const Routes = () => {
  const handleError = (err) => {
    console.error(err);
  }

  return <Switch>
    <Route path="/trainer">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <Trainer />
      </ErrorBoundary>
    </Route>
    <Route path="/groups">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <RangeGroups />
      </ErrorBoundary>
    </Route>
    <Route path="/">
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
        <Viewer />
      </ErrorBoundary>
    </Route>
  </Switch>
}

const Navigation = ({ darkModeEnabled, onToggleDarkMode }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);
  const { logout, isAuthenticated, loginWithRedirect } = useAuth0();
  
  const drawer = (
    <List className={classes.list}>
      <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
        <ListItemText primary={"Dynamic Preflop Viewer"} primaryTypographyProps={{ variant: "subtitle1", style: { fontWeight: 800 } }} />
      </ListItem>
      <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
        <ListItemIcon><ViewerIcon /></ListItemIcon>
        <ListItemText primary={"Viewer"} />
      </ListItem>
      <ListItem button component={Link} to="/trainer" onClick={handleDrawerToggle}>
        <ListItemIcon><TrainerIcon /></ListItemIcon>
        <ListItemText primary={"Trainer"} />
      </ListItem>
      <ListItem button component={Link} to="/groups" onClick={handleDrawerToggle}>
        <ListItemIcon><RangeGroupsIcon /></ListItemIcon>
        <ListItemText primary={"Range Groups"} />
      </ListItem>
      {
        isAuthenticated
          ? <ListItem button onClick={logout}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          : <ListItem button onClick={loginWithRedirect}>
              <ListItemIcon><LoginIcon /></ListItemIcon>
              <ListItemText primary={"Login"} />
            </ListItem>
      }
      
    </List>
  );

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <Typography variant="h6">
            Preflop Range Viewer
          </Typography>
        </div>
        <Hidden smUp implementation="css">
          <IconButton color="inherit" onClick={onToggleDarkMode} size="large">
            {
              darkModeEnabled ? <DarkModeIcon/> : <LightModeIcon/>
            }
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            size="large">
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Tooltip title={"Toggle Dark Mode"}>
            <IconButton color="inherit" onClick={onToggleDarkMode} size="large">
              {
                darkModeEnabled ? <DarkModeIcon/> : <LightModeIcon/>
              }
            </IconButton>
          </Tooltip>
          <Button component={Link} to="/" color="inherit">Viewer</Button>
          <Button component={Link} to="/trainer" color="inherit">Trainer</Button>
          <Button component={Link} to="/groups" color="inherit">Range Groups</Button>
          {
            isAuthenticated
              ? <Button button onClick={logout} color="inherit">Logout</Button>
              : <Button button onClick={loginWithRedirect} color="inherit">Login</Button>
          }
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

export default ThemedApp;
