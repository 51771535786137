import { comboArrayToObject } from "../util";
import fetchRetry from "fetch-retry";
import { cardsToComboString, cardStringToCards } from "../util/range";
export class APIError extends Error {}

const fetch = fetchRetry(window.fetch, {
  retries: 1,
  retryDelay: 100,
  retryOn: (attempt, error, response) => {
    if (attempt < 1 && (error !== null || response.status >= 400)) {
      return true;
    }
  }
})

const { REACT_APP_API_URL: API_URL = `${window.location.protocol}//${window.location.host}/api` } = process.env;

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
}

const parseResponse = async (resp) => {
  if (!resp.ok) 
    throw new APIError(resp.text());
  return resp.json();
}

const getRangeSets = token => () => {
  return fetch(`${API_URL}/range_sets`, {
    method: "GET",
    mode: "cors",
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  })
    .then(parseResponse)
    .then(data => {
      return data.range_sets.map(range_set => ({
        id: range_set.id,
        name: range_set.name,
        ante: range_set.ante,
        players: range_set.players,
        stack: range_set.stack,
        straddles: range_set.straddles,
        seats: range_set.seats,
        version: range_set.version,
        complete: range_set.complete,
        canTrain: range_set.can_train,
        description: range_set.description,
        group: range_set.group,
      }))
    });
}

const getRangeSetPositions = token => (rangeSetId) => {
  return fetch(`${API_URL}/range_sets/${rangeSetId}/positions`, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  })
    .then(parseResponse)
    .then(data => data.positions);
}

const getNodeData = token => (rangeSetId, position, nodeId, version="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/freqs`;
  const queryString = new URLSearchParams();
  if (nodeId !== "") queryString.append("node_path", nodeId);
  if (version !== "") queryString.append("version", version);
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  })
    .then(parseResponse)
    .then(resp => ({
      nodeId,
      nextOptions: resp.nextOptions,
      frequency: comboArrayToObject(resp.frequency),
      EV: comboArrayToObject(resp.EV),
      terminal: resp.terminal || false
    }));
}

const getNodeActionScores = token => async (rangeSetId, position, rng, hand, nodeId="", version="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/scores`;
  const queryString = new URLSearchParams();
  queryString.append("rng", rng);
  queryString.append("hand", hand);
  if (nodeId !== "") queryString.append("node_path", nodeId);
  if (version !== "") queryString.append("version", version);
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  })
    .then(parseResponse)
    .then(resp => ({
      nodeId,
      nextOptions: resp.scores.map(i => i.action),
      frequency: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.freq
      }), {}),
      EV: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.EV
      }), {}),
      scores: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.score
      }), {}),
      dEVs: resp.scores.reduce((acc, next, i) => ({
        ...acc,
        [next.action]: next.dEVs
      }), {}),
    }));
}

const dealHand = token => (rangeSetId, position, rangeSubsets, nodeId="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/deal`;
  const queryString = new URLSearchParams();
  if (nodeId !== "") queryString.append("node_path", nodeId);
  rangeSubsets.forEach(i => queryString.append("range_subset", i));
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  })
    .then(parseResponse)
    .then(resp => (resp.cards.map(i => ({
      position: i.position,
      cards: cardStringToCards(i.combo),
    }))))
    ;
}

const getActions = token => (rangeSetId, position, seats, n, nodeId="") => {
  let endpoint = `${API_URL}/range_sets/${rangeSetId}/positions/${position}/actions`;
  const queryString = new URLSearchParams();
  queryString.append("n", n);
  seats.slice(seats.findIndex(i => i.position === position), seats.length).forEach(i => {
    queryString.append("hands", cardsToComboString(i.cards));
    queryString.append("player_positions", i.position);
  });
  if (nodeId !== "") queryString.append("node_path", nodeId);
  endpoint += `?${queryString.toString()}`;
  return fetch(endpoint, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  })
    .then(parseResponse);
}

const APIClient = token => ({
  getRangeSets: getRangeSets(token),
  getRangeSetPositions: getRangeSetPositions(token),
  getNodeData: getNodeData(token),
  getNodeActionScores: getNodeActionScores(token),
  getActions: getActions(token),
  dealHand: dealHand(token),
})

export default APIClient;
