import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgressWithLabel from './LinearProgressWithLabel';

const useStyles = makeStyles((theme) => ({
  optimal: {
    background: "#4caf50",
  },
  suboptimal: {
    background: "#f44336",
  }
}));

function EvaluationBar({ optimalThreshold=50, value, ...props }) {
  const classes = useStyles();
  return (
    <LinearProgressWithLabel
      classes={{barColorPrimary: value && (value >= optimalThreshold ? classes.optimal : classes.suboptimal) }}
      value={value}
      {...props}
    />
  );
}


export default EvaluationBar;