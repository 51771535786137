import React from "react"
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from "@mui/material";
import { SubdirectoryArrowRight as ChevronRight } from "@mui/icons-material";
import Action from "./Action";
import {
  actionIdToString,
  gameTreeToPotDetails,
  actionInBB
} from "../util/gameTree";

export const GameTree = ({gameTreePath=[], showEllipsis, rightContent, currentRangeSetDetails}) => {
  return (
    <List aria-label="game tree">
      <ListItem disableGutters>
        <ListItemText primary={"/"} />
      </ListItem>
      {
        gameTreePath.map((i, idx) => <ListItem key={idx} disableGutters>
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
          <ListItemText primary={
            <Action
              name={actionIdToString(i)}
              size={actionInBB(i, gameTreeToPotDetails(gameTreePath.slice(0, idx), currentRangeSetDetails))}
              styles={{fontSize:"0.9rem"}}
            />
          }/>
          {rightContent && <ListItemSecondaryAction>
            {rightContent(i, idx)}
          </ListItemSecondaryAction>}
        </ListItem>)
      }
      {showEllipsis && <ListItem disableGutters>
        <ListItemIcon>
          <ChevronRight />
        </ListItemIcon>
        <ListItemText primary={"..."} />
      </ListItem>}
    </List>
  )
}

export default GameTree;
