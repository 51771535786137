import {
  Box,
} from "@mui/material";
import { Alert } from '@mui/material';

export const BasicAccessAlert = () => {
  return <Box mb={1}>
    <Alert
      severity="info"
    >
      Basic access only
    </Alert>
  </Box>
}

export const NoAccessAlert = () => {
  return <Box mb={1}>
    <Alert
      severity="error"
    >
      You do not currently have access to any Ranges.
  </Alert>
  </Box>
}

const AccessAlerts = {
  BasicAccessAlert,
  NoAccessAlert
}

export default AccessAlerts;
