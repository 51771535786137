import React from "react"
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { RANGE_STRING_GTOPLUS, RANGE_STRING_SIMPLE_PREFLOP, RANGE_STRING_PRO_POKER_TOOLS, RANGE_STRING_MONKER } from "../util/range";
import { CONFIG_VALUE_ENABLED, CONFIG_VALUE_DISABLED } from "../util/config";
import { colorPalettes } from "../util/colors";
import { useConfig } from "../hooks";

const SAMPLE_MODE_HINT_NONE = "none";
const SAMPLE_MODE_HINT_ACTION_HOVER = "action_hover";

export const CONFIG_KEYS = {
  palette: "palette",
  frequencyMode: "frequencyMode",
  optionsMode: "optionsMode",
  rangeStringFormat: "rangeStringFormat",
  sampleModeHinting: "sampleModeHinting",
  weightedMode: "weightedMode",
  normalizeWeightedMode: "normalizeWeightedMode",
  normalizeRangeString: "normalizeRangeString"
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  select: {
    width: "100%"
  },
  dialogBody: {
    padding: 20,
    paddingTop: 0
  },
}));

const ViewerConfigDialog = ({ onClose, open, loading, visibleControls=[CONFIG_KEYS.palette, CONFIG_KEYS.rangeStringFormat, CONFIG_KEYS.sampleModeHinting, CONFIG_KEYS.normalizeWeightedMode] }) => {
  const classes = useStyles();
  const [config, handleConfigChange] = useConfig();
  const isConfigControlShown = (configKey) => {
    return visibleControls.indexOf(configKey) !== -1;
  }

  return (
    <Dialog maxWidth="xs" fullWidth={true} onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="settings-dialog-title">Config</DialogTitle>
      <div className={classes.dialogBody}>
        {isConfigControlShown(CONFIG_KEYS.palette) && <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="palette-label">Color Palette</InputLabel>
          <Select
            labelId="color-palette-label"
            id="color-palette-select"
            value={config.palette}
            placeholder="Select a color palette"
            onChange={e => handleConfigChange(CONFIG_KEYS.palette, e.target.value)}
            className={classes.select}
            disabled={loading}
          >
            {colorPalettes.map(cs => <MenuItem key={cs} value={cs}>{cs}</MenuItem>)}
          </Select>
        </FormControl>}
        {isConfigControlShown(CONFIG_KEYS.rangeStringFormat) &&  <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="range-string-label">Range String Format</InputLabel>
          <Select
            labelId="range-string-label"
            id="range-string-select"
            value={config.rangeStringFormat}
            placeholder="Select a range string format"
            onChange={e => handleConfigChange(CONFIG_KEYS.rangeStringFormat, e.target.value)}
            className={classes.select}
            disabled={loading}
          >
            <MenuItem value={RANGE_STRING_GTOPLUS}>GTO+/Pio</MenuItem>
            <MenuItem value={RANGE_STRING_SIMPLE_PREFLOP}>Simple Preflop</MenuItem>
            <MenuItem value={RANGE_STRING_PRO_POKER_TOOLS}>ProPokerTools</MenuItem>
            <MenuItem value={RANGE_STRING_MONKER}>Monker</MenuItem>
          </Select>
        </FormControl>}
        {isConfigControlShown(CONFIG_KEYS.sampleModeHinting) && <FormControl variant="standard" className={classes.formControl}>
          <InputLabel id="sample-indicator-label">Sample Mode Action Hinting</InputLabel>
          <Select
            labelId="sample-indicator-label"
            id="sample-indicator-select"
            value={config.sampleModeHinting}
            placeholder="Select a mode"
            onChange={e => handleConfigChange(CONFIG_KEYS.sampleModeHinting, e.target.value)}
            className={classes.select}
            disabled={loading}
          >
            <MenuItem value={SAMPLE_MODE_HINT_NONE}>None</MenuItem>
            <MenuItem value={SAMPLE_MODE_HINT_ACTION_HOVER}>Highlight combos on action hover</MenuItem>
          </Select>
          <FormHelperText>How to indicate the related action for a combo in sample mode</FormHelperText>
        </FormControl>}
        {isConfigControlShown(CONFIG_KEYS.normalizeWeightedMode) && <FormControl className={classes.formControl}>
          <FormControlLabel
            control={<Switch onChange={e => handleConfigChange(CONFIG_KEYS.normalizeWeightedMode, e.target.checked ? CONFIG_VALUE_ENABLED : CONFIG_VALUE_DISABLED)} checked={config.normalizeWeightedMode === CONFIG_VALUE_ENABLED} />}
            label="Normalize Weighted Mode"
          />
        </FormControl>}
        <FormControl className={classes.formControl}>
          <FormHelperText>If you have recently purchased new ranges, logout and log back in to immediately refresh permissions</FormHelperText>
        </FormControl>
      </div>
    </Dialog>
  )
}

export default ViewerConfigDialog;
