import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import { ConfigProvider } from "./providers/ConfigContext";
const { REACT_APP_CLIENT_ID: CLIENT_ID } = process.env;
const { REACT_APP_AUTH_DOMAIN: AUTH_DOMAIN } = process.env;

const renderApp = () => {
  ReactDOM.render(
    // <React.StrictMode>
    <Auth0Provider
      domain={AUTH_DOMAIN}
      clientId={CLIENT_ID}
      redirectUri={window.location.origin}
      audience="https://viewer.rapidpt.org/api"
      useRefreshTokens={true}
    >
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </Auth0Provider>,
    // </React.StrictMode>,
    document.getElementById('root')
  );
}

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
