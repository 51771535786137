import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    minWidth: 20,
    width: 20,
    minHeight: 20,
    height: 20,
    marginRight: 5,
    border: "1px solid #e7e7e7",
  },
  actionName: {
    textAlign: "left",
    flexGrow: 1
  }
}));

export const ActionIcon = ({backgroundColor}) => {
  const classes = useStyles();
  return <div className={classes.actionIcon} style={{background: backgroundColor}}/>
}

export const Action = ({name, color, size, fullWidth=true, styles={}}) => {
  const classes = useStyles();
  return <div style={{display: "flex", alignItems: "center", width: fullWidth ? "100%" : "auto", ...styles}}>
    {color && <><ActionIcon backgroundColor={color}/>{" "}</>}
    <span className={classes.actionName}>{name}</span>
    {size !== undefined && <span>({size.toFixed(2)}BB)</span>}
  </div>
}

export default Action;
