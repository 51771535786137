import React, { useEffect, useState } from "react"
import { useErrorHandler } from "react-error-boundary";
import { HandMatrix } from "@holdem-poker-tools/hand-matrix"
import {
  Box,
  Grid,
  FormControl,
  Typography,
  InputLabel,
  CircularProgress,
  MenuItem,
  Select,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Popover,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  Refresh,
  Settings,
  Add,
  Warning,
  Edit,
  ArrowBack,
  ArrowForward,
  Delete,
  InfoOutlined
} from "@mui/icons-material";
import {
  usePopupState,
  bindTrigger,
  bindPopover,
  anchorRef,
} from "material-ui-popup-state/hooks";
import { Alert } from '@mui/material';
import LoadingPlaceholder from "../components/Loading";
import StrategyTable from "../components/StrategyTable";
import APIClient from "../api";
import {
  sampleComboStyler,
  frequencyComboStyler,
  getRandomInt,
  getOverallFrequencies,
  getOverallEV,
  comboFrequenciesToPercentages,
} from "../util";
import { CONFIG_KEYS, CONFIG_VALUE_ENABLED } from "../util/config";
import { getRangeGroups, storeRangeGroup, deleteRangeGroup, subscribe } from "../groups";
import {
  actionIdToString,
} from "../util/gameTree";
import { getActionColor } from "../util/colors";
import { useConfig } from "../hooks";
import ViewerControls from "../components/ViewerControls";
import ViewerConfigDialog from "../components/ViewerConfigDialog";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%"
  },
  formControlInline: {
    minWidth: 120,
    width: "100%"
  },
  select: {
    width: "100%"
  },
  group: {
    marginLeft: 7,
    paddingLeft: 7,
    borderLeft: `1px dashed grey`,
  },
  controls: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  control: {
    marginLeft: 5,
    marginRight: 5
  },
  dialogBody: {
    padding: 20,
    paddingTop: 0
  },
  loader: {
    position: "fixed",
    width: "100%",
    textAlign: "center",
    bottom: 5,
    left: 0
  }
}));

function RangeGroups() {
  const handleError = useErrorHandler();
  const classes = useStyles();
  // App State
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('sm'));
  const [hasErrored, setHasErrored] = useState(false);
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [showConfigDialog, setShowConfigDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(null);
  const [seed, setSeed] = useState(0);
  const [config] = useConfig();

  const frequencyModeEnabled = config.frequencyMode === CONFIG_VALUE_ENABLED;
  const currentGroupDetails = currentGroup && groups.find(i => i.id === currentGroup);

  useEffect(() => {
    setLoading(true);
    getRangeGroups()
      .then(groups => {
        setGroups(groups)
        groups.length && setCurrentGroup(groups[0].id);
      })
      .catch(err => {
        console.warn(err);
        setHasErrored(true);
        handleError(new Error("Unable to load groups!"));
      })
      .finally(() => {
        setLoading(false);
      })
  }, [setLoading, setGroups, setCurrentGroup, setHasErrored, handleError]);

  useEffect(() => {
    const subPromise = subscribe(({newValue}) => {
      if (newValue) {
        setGroups(newValue);
      }
    })
    return () => subPromise.then(sub => sub.unsubscribe());
  }, [setGroups]);
  
  const handleGroupChange = (groupId) => {
    setCurrentGroup(groupId);
  }

  const handleResample = () => {
    setSeed(getRandomInt(1, 1000));
  }

  const refreshGroups = () => getRangeGroups().then(setGroups)

  const handleDelete = (id) => {
    deleteRangeGroup(id)
      .then(() => {
        const newGroups = groups.filter(i => i.id !== id);
        setCurrentGroup(newGroups.length > 0 ? newGroups[0].id : null);
        setEditing(null);
      });
  }

  const handleSubmit = (data) => {
    setEditing(null);
    storeRangeGroup(data)
      .then(async (group) => {
        setCurrentGroup(group.id)
      })
  }

  const handleChangeOrder = (currentIndex, newIndex) => {
    const ranges = [...currentGroupDetails.ranges];
    ranges.splice(newIndex, 0, ranges.splice(currentIndex, 1)[0]);
    storeRangeGroup({
      ...currentGroupDetails,
      ranges
    })
  }

  const handleDeleteRange = (idx) => {
    storeRangeGroup({
      ...currentGroupDetails,
      ranges: currentGroupDetails.ranges.filter((_, index) => idx !== index)
    })
  }
  
  useEffect(() => {
    setSeed(getRandomInt(1, 1000));
  }, [config.frequencyMode]);

  if (hasErrored) return <Box mb={1}>
    <Alert
      severity="error"
    >
      Something went wrong. Please clear you cache and reload the page.
    </Alert>
  </Box>
  
  return (
    <div>
      <RangeGroupDialog initValues={editing} open={editing !== null} onClose={() => setEditing(null)} onSubmit={handleSubmit} onDelete={handleDelete}/>
      <Box mb={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <div style={{display: "flex"}}>
              <FormControl variant="standard" className={classes.formControlInline}>
                <InputLabel id="group-label">Range Group</InputLabel>
                <Select
                  labelId="group-label"
                  id="group-select"
                  value={currentGroup || ""}
                  placeholder="Select a group"
                  onChange={e => handleGroupChange(e.target.value)}
                  className={classes.select}
                  disabled={loading || groups.length === 0}
                >
                  {groups.map(group => <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>)}
                </Select>
              </FormControl>
              <IconButton disabled={!currentGroup} style={{minWidth: 48}} onClick={() => setEditing(currentGroupDetails)} size="small" aria-label="edit">
                <Edit />
              </IconButton> 
              <IconButton disabled={!currentGroup} style={{minWidth: 48}} onClick={refreshGroups} size="small" aria-label="edit">
                <Refresh />
              </IconButton> 
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControlInline} style={{ paddingTop: compact ? 0 : 12 }}>
              <Button color="primary" variant="outlined" onClick={() => setShowConfigDialog(e => !e)}><Settings /> Viewer Config</Button>
            </FormControl>
            <ViewerConfigDialog
              config={config}
              open={showConfigDialog}
              loading={loading}
              onClose={() => setShowConfigDialog(false)}
              visibleControls={[CONFIG_KEYS.palette, CONFIG_KEYS.normalizeWeightedMode]}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControlInline} style={{ paddingTop: compact ? 0 : 12 }}>
              <Button color="primary" variant="contained" onClick={() => setEditing({ranges: [], name: ""})}><Add /> Create New</Button>
            </FormControl>
          </Grid>
          {
            loading && <div className={classes.loader}>
              <CircularProgress size={20} />
            </div>
          }
        </Grid>
      </Box>

      <Box className={classes.controls} mb={1}>
        <ViewerControls/>
        <Button fullWidth={false} disabled={frequencyModeEnabled} endIcon={<Refresh />} variant="outlined" color="primary" onClick={handleResample}>Resample</Button>
      </Box>

      {!loading && groups.length > 0 && currentGroupDetails?.ranges && currentGroupDetails?.ranges.length === 0 && <Alert
        severity="info"
      >
        You have no ranges in this group yet. Add ranges to it from the <Link to="/viewer">Viewer</Link>
      </Alert>}
      {!loading && groups.length === 0 && <Alert
        severity="info"
      >
        You have no range groups yet. Create a new group then add ranges to it from the <Link to="/viewer">Viewer</Link>
      </Alert>}
      <Grid container spacing={2}>
        {
          currentGroupDetails && currentGroupDetails.ranges.map((range, idx, arr) => <Grid key={range.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Range
             seed={seed}
             onDelete={() => handleDeleteRange(idx)}
             onMoveBack={() => handleChangeOrder(idx, idx - 1)}
             onMoveForward={() => handleChangeOrder(idx, idx + 1)}
             canMoveBack={idx !== 0 && arr.length !== 0}
             canMoveForward={idx !== arr.length - 1 && arr.length !== 0}
             {...range}
             />
          </Grid>)
        }
      </Grid>
    </div>
  );
}

const Range = ({ id, gameTreePath, position, rangeSet, name, version, seed, onMoveBack, onMoveForward, onDelete, canMoveForward, canMoveBack }) => {
  const [loading, setLoading] = useState(true);
  const [config] = useConfig();
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [selectedCombo, setSelectedCombo] = useState(undefined);
  const popupState = usePopupState({ variant: 'popover', popupId: id });
  const { getAccessTokenSilently } = useAuth0();

  const frequencyModeEnabled = config.frequencyMode === CONFIG_VALUE_ENABLED;
  const weightedModeEnabled = config.weightedMode === CONFIG_VALUE_ENABLED;
  const normalizeWeightedMode = config.normalizeWeightedMode === CONFIG_VALUE_ENABLED;

  useEffect(() => {
    if (!popupState.isOpen) {
      setSelectedCombo(undefined);
    }
  }, [setSelectedCombo, popupState.isOpen])

  useEffect(() => {
    getAccessTokenSilently()
      .then(APIClient)
      .then(client => client.getNodeData(rangeSet, position, gameTreePath, version))
      .then((data) => {
        const actions = data.frequency && Object.values(data.frequency).length > 1 ? Object
          .keys(Object.values(data.frequency)[0])
          .map((action, idx, src) => {
            return {
              id: action,
              name: actionIdToString(action),
              color: getActionColor(action, src, config.palette),
            }
          }) : [];
        const result = {...data, actions}
        setData(result)
      })
      .catch((err) => {
        console.warn(err)
        setError(err.message)
      })
      .finally(() => setLoading(false));
  }, [config.palette, gameTreePath, position, rangeSet, version, getAccessTokenSilently])

  const styler = frequencyModeEnabled ? frequencyComboStyler : sampleComboStyler(seed, false)
  const overallFrequencies = (data.frequency && Object.keys(data.frequency)) ? getOverallFrequencies(data.frequency) : {};
  const overallEV = (data.frequency && Object.keys(data.frequency)) ? getOverallEV(data.frequency, data.EV) : {};

  if (loading) {
    return <LoadingPlaceholder/>
  }

  if (error) {
    return <Box style={{textAlign: "center", flexGrow: 1}}>
      <Warning/>
      <Typography>Unable to load range</Typography>
    </Box>
  }

  return <Box style={{height: "100%", display: "flex", flexDirection: "column"}}>
    <Box style={{display: "flex"}}>
      <Typography style={{flexGrow: 1}} variant="subtitle1">{name}</Typography>
      <IconButton onClick={onDelete} size="small" aria-label="edit">
        <Delete />
      </IconButton> 
    </Box>
    <HandMatrix
      comboStyle={styler(data.frequency, data.actions, weightedModeEnabled, normalizeWeightedMode, combo => ({
        fontSize: "0.75rem",
        boxShadow: selectedCombo === combo ? "0 0 1px 3px #ffffff inset" : "none"
      }))}
      colorize={false}
      onSelect={(combo) => {
        setSelectedCombo(selectedCombo === combo ? undefined : combo)
        popupState.setOpen(selectedCombo !== combo)
      }}
    />
    <Box style={{justifyContent: "space-between"}}>
      <IconButton size="small" aria-label="edit" {...bindTrigger(popupState)} ref={anchorRef(popupState)}>
        <InfoOutlined/>
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
          <Box style={{padding: 15, maxWidth: 400}}>
            {
              selectedCombo
                ? <Box>
                    <Typography variant="subtitle1">{selectedCombo} Strategy {`(${Math.min(100, (Object.values(data.frequency[selectedCombo]).reduce((a, b) => a + b, 0) * 100)).toFixed(1)}%)`}</Typography>
                    <StrategyTable size="small" percentages={comboFrequenciesToPercentages(data.frequency[selectedCombo])} evData={data.EV[selectedCombo]} actions={data.actions} />
                  </Box>
                : <Box>
                  <Typography variant="subtitle1">Overall Strategy</Typography>
                  <StrategyTable size="small" percentages={overallFrequencies} evData={overallEV} actions={data.actions} />
                </Box>
            }
          </Box>
      </Popover>
      <IconButton disabled={!canMoveBack} onClick={onMoveBack} size="small" aria-label="edit">
        <ArrowBack />
      </IconButton>
      <IconButton disabled={!canMoveForward} onClick={onMoveForward} size="small" aria-label="edit">
        <ArrowForward />
      </IconButton>
    </Box>
  </Box>
}

const RangeGroupDialog = ({ initValues={}, open, onClose, onSubmit, onDelete }) => {
  const classes = useStyles();
  const [data, setData] = useState(initValues || {});
  const [error, setError] = useState(null);

  const handleDelete = () => {
    onDelete(data.id);
  }

  const handleSubmit = () => {
    onSubmit(data);
  }

  const handleClose = () => {
    onClose();
  }

  const handleChange = k => e => {
    const val = e.target.value;
    setData({...data, [k]: val});
  };

  useEffect(() => {
    const limit = 50;
    data?.name?.length > 0 && setError(
      !data?.name || data?.name?.length >= limit ? `Please use a name with less than ${limit} characters` : null
    );
  }, [setError, data])

  useEffect(() => {
    setData(initValues)
  }, [setData, initValues])

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm">
      <DialogTitle>Range Group</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl}>
          <TextField variant="standard" fullWidth={true} error={error} id="input-title" label="Range Group Name" value={data?.name || ""} onChange={handleChange("name")} helperText={error}/>
        </FormControl>
        <DialogActions>
          {initValues?.id && <Button onClick={handleDelete}>
            Delete
          </Button>}
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={error || !data?.name || data.name.length === 0} onClick={handleSubmit} color="primary">
            Ok
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}


export default RangeGroups;
