import React, { useEffect, useRef } from "react";
import { Button } from '@mui/material';
import { Alert } from '@mui/material';

const ErrorFallback = ({error, resetErrorBoundary}) => {
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!firstUpdate.current) resetErrorBoundary();
  }, [resetErrorBoundary]);

  return (
    <Alert severity="error" action={<Button onClick={resetErrorBoundary}>Reload</Button>}>
      {error.message} Please clear your cache and reload. If the issue persists let us know.
    </Alert>
  )
}

export default ErrorFallback;
