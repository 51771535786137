import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ExpandMore
} from '@mui/icons-material';
import { actionIdToString, actionInBB } from "../util/gameTree"
import { comboFrequenciesToPercentages, toFixedIfRequired, evToBB, getRngBoundary } from '../util';
import { useEffect, useState } from 'react';
import { green, red, yellow } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  th: {
    fontWeight: "bold"
  }
}));

const HandEvaluation = ({ decisions=[] }) => {
  const [expanded, setExpanded] = useState(decisions.map((i, index) => index === decisions.length -1));

  const getBestDecision = (scores) =>  Object.keys(scores).reduce((a, b) => scores[a] > scores[b] ? a : b);

  useEffect(() => {
    setExpanded(decisions.map((i, index) => index === decisions.length -1));
  }, [decisions, setExpanded]);

  const toggleExpanded = (idx) => {
    const existing = [...expanded];
    existing[idx] = !existing[idx];
    setExpanded(existing)
  }

  return <div>
    {
      decisions.length === 0 && <Typography>No decisions made yet.</Typography>
    }
    {
      decisions.map(({score, scores, dEVs, chosenOption, rng, potDetails, frequency, EV}, idx) => {
        const bestDecision = getBestDecision(scores);
        const bestDecisionInBB = toFixedIfRequired(actionInBB(bestDecision, potDetails), 2);
        const chosenOptionInBB = toFixedIfRequired(actionInBB(chosenOption, potDetails), 2);
        return <Box key={idx} mt={idx === 0 ? 0 : 1}>
          <Box mb={1}>
            <Accordion  TransitionProps={{unmountOnExit: true, timeout: 0}} onChange={() => toggleExpanded(idx)} expanded={expanded[idx] || false} square>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box>
                  <Typography><strong>Decision:</strong> {actionIdToString(chosenOption)} ({chosenOptionInBB}BB)</Typography>
                  <Typography><strong>Optimal Decision:</strong> {actionIdToString(bestDecision)} ({bestDecisionInBB}BB)</Typography>{" "}
                  <Typography><strong>Score:</strong> {toFixedIfRequired(score, 1)}/100</Typography>
                  <Typography><strong>dEV:</strong> {toFixedIfRequired(evToBB(dEVs[chosenOption]), 2)}BB</Typography>
                  <Typography><strong>RNG:</strong> {rng}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails style={{padding: 0}}>
                <ScoreTable
                  chosenOption={chosenOption}
                  scores={scores}
                  bestDecision={bestDecision}
                  frequency={comboFrequenciesToPercentages(frequency)}
                  EV={EV}
                  potDetails={potDetails}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      })
    }
  </div>
}

const ScoreTable = ({ bestDecision, frequency, EV, potDetails, chosenOption, scores }) => {
  const classes = useStyles();

  const getActionStyles = (action) => {
    if (
      action === bestDecision
      || scores[action] === 100
    ) return {fontWeight: 800, color: green[600]};
    if (action === chosenOption && scores[action] === 0) return {fontWeight: 800, color: red[700]};
    if (action === chosenOption && scores[action] > 0 && scores[action] < 100) return {fontWeight: 800, color: yellow[800]};
    return {fontWeight: 500};
  }

  const rngBoundaryGetter = getRngBoundary(frequency);

  return (<TableContainer>
    <Table aria-label="score table" size="small">
      <TableHead>
        <TableRow>
          <TableCell className={classes.th}>Action</TableCell>
          <TableCell className={classes.th} align="right">Frequency</TableCell>
          <TableCell width={85} className={classes.th} align="right">RNG</TableCell>
          <TableCell className={classes.th} align="right">EV</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(Object.keys(frequency) || []).map((action, i) => (
          <TableRow key={i}>
            <TableCell component="th" scope="row" style={getActionStyles(action)}>
              {actionIdToString(action)} ({toFixedIfRequired(actionInBB(action, potDetails), 2)}BB)
            </TableCell>
            <TableCell align="right">{toFixedIfRequired(frequency[action], 2)}%</TableCell>
            <TableCell align="right">{rngBoundaryGetter(action)}</TableCell>
            <TableCell align="right">{toFixedIfRequired(evToBB(EV[action]), 2)}BB</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>)
}

export default HandEvaluation;
