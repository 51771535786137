import { useContext, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { ConfigContext } from "../providers/ConfigContext";

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export const useConfig = () => {
  return useContext(ConfigContext);
}

// Hook
export const useDarkMode = () => {
  const [enabledState, setEnabledState] = useLocalStorage('darkModeEnabled');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const enabled =
    typeof enabledState !== 'undefined' ? enabledState : prefersDarkMode;

  return [enabled, setEnabledState];
}

const hooks = {
  useLocalStorage,
  useDarkMode,
  useConfig,
}

export default hooks;
