import { useState, useEffect, useCallback } from "react";
import { useErrorHandler } from "react-error-boundary";
import { useHotkeys } from "react-hotkeys-hook";
import {
  useTheme,
  useMediaQuery,
  Grid,
  Box,
  Tooltip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Button,
  Typography,
  InputLabel,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  CircularProgress,
  SwipeableDrawer,
  MenuItem,
  Fab,
  Select,
  Switch,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
  ListSubheader,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Slider,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Alert, Autocomplete } from '@mui/material';
import {
  PlayArrow,
  History,
  Settings,
  Info,
  Stop
} from '@mui/icons-material';
import EvaluationBar from "../components/EvaluationBar";
import HandEvaluation from "../components/HandEvaluation";
import Card from '../components/Card';
import PokerTable from "../components/PokerTable";
import NextOptions from "../components/NextOptions";
import { StrategyTable } from "../components/StrategyTable";
import GameTree from '../components/GameTree';
import { NoAccessAlert } from "../components/AccessAlert";
import LoadingPlaceholder from "../components/Loading";
import {
  getRandomInt,
  comboFrequenciesToPercentages,
  decisionsToHandScore,
} from '../util';
import {
  gameTreeToPotDetails,
  isStillInHand,
  orderOfAction,
  actionIdToParts,
  gameTreePathToNodeId,
  actionIdToString,
  actionInBB,
  startPositionFromNodeId,
  trimGameTree,
  isFold
} from '../util/gameTree';
import { cardsToComboString } from '../util/range';
import { CONFIG_KEYS, CONFIG_VALUE_ENABLED, CONFIG_VALUE_DISABLED } from "../util/config";
import { getActionColor } from "../util/colors";
import allPresets from "../presets";
import { getHandHistory, storeHand } from "../util/history";
import { useConfig } from "../hooks";
import APIClient from "../api";
import { useAuth0 } from "@auth0/auth0-react";

const getSeat = (hand, position) => hand.seats.find(i => i.position === position);

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(5)
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  formControlInline: {
    width: "100%"
  },
  logo: {
    marginRight: 10,
  },
  logoContainer: {
    flexGrow: 1
  },
  appBar: {
    paddingTop: 4,
    minHeight: 68,
    boxShadow: "none"
  },
  fab: {
    position: "fixed",
    bottom: 20,
    right: 20
  },
  history: {
    width: 360,
    backgroundColor: theme.palette.background.paper,
  },
  optionsContainer: {
    maxWidth: 910,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down('lg')]: {
      textAlign: "center",
    },
  },
  score: {
    width: 35,
    height: 35,
    color: "white",
    background: "black",
    borderRadius: "50%",
    justifyContent: "center",
    fontSize: 16,
    alignItems: "center",
    verticalAlign: "middle",
    alignSelf: "center",
    display: "flex",
    textTransform: "uppercase",
    fontWeight: 900,
    flexDirection: "column"
  },
  optimal: {
    background: "#018801"
  },
  suboptimal: {
    background: "#dc4e3d"
  },
  dialogBody: {
    padding: 20,
    paddingTop: 0
  },
  rangeWarning: {
    color: theme.palette.warning.dark
  }
}));

const COMPACT_BREAKPOINT = "xs";
const DONE_STATUS = "done";
const IN_PROGRESS_STATUS = "in_progress";

function Trainer() {
  const initialScenario = {
    rangeSet: "",
    positions: [],
  };
  const initialSessionScore = {
    hands: 0,
    totalScore: 0
  }
  const classes = useStyles();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const compact = useMediaQuery(theme.breakpoints.down(COMPACT_BREAKPOINT));
  const handleError = useErrorHandler();
  const compactButtons = useMediaQuery(theme.breakpoints.down('lg'));
  const [config, handleConfigChange] = useConfig();
  const [filters, setFilters] = useState({ presets: [], startPositions: [], startNodes: [], enabledForHero: [] });
  const [loading, setLoading] = useState(true);
  const [rangeSets, setRangeSets] = useState([]);
  const [positions, setPositions] = useState([]);
  const [loadedRangeSets, setLoadedRangeSets] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);
  const [showTrainerConfigDialog, setShowTrainerConfigDialog] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [scenario, setScenario] = useState(initialScenario);
  const [playing, setPlaying] = useState(false);
  const [history, setHistory] = useState([]);
  const [currentHand, setCurrentHand] = useState(undefined);
  const [tab, setTab] = useState(0);
  const [sessionScore, setSessionScore] = useState(initialSessionScore);
  const [data, setData] = useState({});
  const [handlingDecision, setHandlingDecision] = useState(false);
  const [rng, setRng] = useState(undefined);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getHandHistory()
      .then(setHistory)
      .catch(() =>  console.warn("Unable to retrieve hand history!"));
  }, [setHistory]);

  useEffect(() => {
    setLoading(true);
    getAccessTokenSilently()
      .then(APIClient)
      .then(client => client.getRangeSets())
      .then(data => {
        const rangeSets = data.filter(i => i.canTrain);
        setRangeSets(rangeSets)
        rangeSets.length && setScenario(e => ({ ...e, rangeSet: rangeSets[0].id }))
      })
      .catch(err => {
        console.warn(err);
        setHasErrored(true);
        handleError(new Error("Unable to load range sets!"))
      })
      .finally(() => {
        setLoading(false);
        setLoadedRangeSets(true);
      })
  }, [setLoading, setRangeSets, setScenario, setLoadedRangeSets, handleError, setHasErrored, getAccessTokenSilently]);

  useEffect(() => {
    if (scenario.rangeSet !== "") {
      setLoading(true);
      const rangeSetData = rangeSets.find(i => i.id === scenario.rangeSet);
      getAccessTokenSilently()
        .then(APIClient)
        .then(client => client.getRangeSetPositions(scenario.rangeSet))
        .then(positions => {
          setPositions(positions)
          setScenario(e => ({ ...e, positions }))
          setFilters(e => ({
            ...e,
            presets: [],
            startPositions: rangeSetData.seats.reduce((acc, next, idx, arr) => {
              const enabled = positions.indexOf(next) !== -1 || arr.slice(0, idx).some(i => acc[i])
              return { ...acc, [next]: enabled }
            }, {}),
            enabledForHero: rangeSetData.seats.slice(rangeSetData.seats.indexOf(positions[0])).reduce((acc, next) => ({ ...acc, [next]: true }), { BB: true, ST: true }),
          }))
        })
        .catch(err => {
          console.warn(err);
          setHasErrored(true);
          handleError(new Error("Unable to load range set positions!"));
        })
        .finally(() => setLoading(false))
    }
  }, [scenario.rangeSet, rangeSets, setScenario, setPositions, setLoading, setFilters, handleError, setHasErrored, getAccessTokenSilently]);

  const handleScenarioChange = (newScenario) => {
    setScenario(newScenario)
  }

  const handleFilterChange = (filters) => {
    setFilters(filters)
  }

  const handlePlayToggle = () => {
    if (!playing) {
      startPlay();
    } else {
      stopPlay();
    }
  }

  const stopPlay = () => {
    setTab(0);
    setSessionScore(initialSessionScore);
    setPlaying(false);
    setCurrentHand(undefined);
    setData({});
    setRng(undefined);
  }

  const startPlay = () => {
    setHasErrored(false);
    setTab(0);
    setPlaying(true);
    setData({});
    startNextHand();
  }

  const refreshRng = () => {
    setRng(getRandomInt(0, 100));
  }

  const getValidPositions = (seats, allowedPositions) => seats
    // Only allow hero to be seated in unfiltered, permitted seats
    .filter(i => allowedPositions.indexOf(i) !== -1);

  const startNextHand = useCallback(async () => {
    try {
      refreshRng();
      setData({});
      setLoading(true);
      const rangeSetData = rangeSets.find(i => i.id === scenario.rangeSet);
      let startNode = "";
      let enabledForHero = Object.keys(filters.enabledForHero).filter(i => filters.enabledForHero[i]);
      let startPositions = Object.keys(filters.startPositions).filter(i => filters.startPositions[i]);
      if (filters.presets.length > 0) {
        // Select a random preset from the presets if set
        const randomPreset = filters.presets[getRandomInt(1, filters.presets.length) - 1]
        // Select a random start node from that preset
        startNode = randomPreset.startNodes[getRandomInt(1, randomPreset.startNodes.length) - 1];
        // Limit hero to sitting in the valid seats. We don't need to work out the start positions
        // because we can get from the game tree path
        enabledForHero = randomPreset.enabledForHero;
      }
      const gameTreePath = startNode.split("/").filter(i => i !== "");
      // Only allow unfiltered start positions, and in the case a preset is used, don't allow seats which have folded already
      const alreadyFolded = rangeSetData.seats.filter(i => {
        const gameTreeFirstActor = gameTreePath.length > 0 ? actionIdToParts(gameTreePath[0]).position : undefined;
        return gameTreePath.indexOf(`${i}f`) !== -1 || (gameTreeFirstActor && rangeSetData.seats.indexOf(i) < rangeSetData.seats.indexOf(gameTreeFirstActor));
      })
      const validStartPositions = getValidPositions(rangeSetData.seats, startPositions.filter(i => alreadyFolded.indexOf(i) === -1));
      const validHeroPositions = getValidPositions(rangeSetData.seats, enabledForHero.filter(i => alreadyFolded.indexOf(i) === -1));
      const heroPosition = validHeroPositions[getRandomInt(1, validHeroPositions.length) - 1];
      const startPosition = validStartPositions[0];
      const startPositionIndex = rangeSetData.seats.indexOf(startPosition);
      const trimmed = rangeSetData.seats.slice(startPositionIndex);
      const heroPositionIndex = trimmed.indexOf(heroPosition);
      const rangeSubsets = trimmed.map((p, idx) => idx === heroPositionIndex ? config.heroRange || '100' : config.villainRanges || '100');
      const dealtPlayers = await getAccessTokenSilently()
        .then(APIClient)
        .then(client => client.dealHand(scenario.rangeSet, startPosition, rangeSubsets, startNode));
      const seats = rangeSetData.seats.map(i => ({
        position: i,
        cards: dealtPlayers.find(p => p.position === i)?.cards || [],
        sittingOut: dealtPlayers.find(p => p.position === i) === undefined,
      }));
      const order = orderOfAction(gameTreePath, startPosition, rangeSetData.seats);
      setCurrentHand({
        status: IN_PROGRESS_STATUS,
        seats,
        heroPosition,
        startPosition,
        decisions: [],
        rangeSetData,
        gameTreePath,
        nextToAct: order[0]
      })
    } catch (err) {
      console.error(err);
      setHasErrored(true);
      handleError(new Error("Unable to start hand!"))
    } finally {
      setLoading(false);
    }
  }, [filters, handleError, rangeSets, scenario, config, getAccessTokenSilently])

  const handleActionSelection = useCallback(async (actions) => {
    try {
      setHandlingDecision(true);
      setData({});
      const rangeSetData = currentHand.rangeSetData;
      let gameTreePath = currentHand.gameTreePath;
      let startPosition = currentHand.startPosition;
      let decisions = currentHand.decisions;
      // Work out who the next valid start position is
      const validStartPositions = getValidPositions(rangeSetData.seats, positions);
      // Work through each action in order
      await actions.reduce((promise, action) => promise.then(async () => {
        const { position } = actionIdToParts(action);
        // Determine the next valid start position
        const nextValidStartPosition = validStartPositions[validStartPositions.indexOf(startPosition) + 1];
        // If the actor is hero, we should determine hero's score
        if (position === currentHand.heroPosition) {
          const scoresResp = await getAccessTokenSilently()
            .then(APIClient)
            .then(client => client.getNodeActionScores(
            currentHand.rangeSetData.id,
            startPosition,
            rng,
            cardsToComboString(getSeat(currentHand, currentHand.heroPosition).cards),
            gameTreePathToNodeId(trimGameTree(gameTreePath)) // Remove any preceding folds
          ))
          const scores = Object.fromEntries(Object.entries(scoresResp.scores).map(([choice, score]) => [choice, score * 100]));
          const score = config.rngScoring === CONFIG_VALUE_ENABLED
            ? scores[actions[0]]
            : scores[actions[0]] > 0 ? 100 : 0;
          decisions.push({
            score,
            scores,
            frequency: scoresResp.frequency,
            EV: scoresResp.EV,
            dEVs: scoresResp.dEVs,
            rng,
            chosenOption: actions[0],
            potDetails: gameTreeToPotDetails(currentHand.gameTreePath, currentHand.rangeSetData),
          });
          refreshRng();
        }
        // Update the game tree path if the position hasn't been force folded
        if (gameTreePath.indexOf(`${position}f`) === -1) {
          gameTreePath = gameTreePath.concat([action]);
        }
        // If the pot is unopened shift start position to the next permitted start position and force fold everyone in between
        const isUnopened = gameTreePath.every(isFold);
        if (isUnopened) {
          gameTreePath = gameTreePath.concat(rangeSetData.seats.slice(
            rangeSetData.seats.indexOf(position) + 1,
            rangeSetData.seats.indexOf(nextValidStartPosition)
          ).map(i => `${i}f`));
          startPosition = nextValidStartPosition;
        }
      }), Promise.resolve());
      const isFinished = handFinished({ ...currentHand, gameTreePath });
      const hand = {
        ...currentHand,
        gameTreePath,
        status: isFinished ? DONE_STATUS : IN_PROGRESS_STATUS,
        nextToAct: orderOfAction(gameTreePath, startPosition, currentHand.seats.map(i => i.position))[0],
        startPosition,
      }
      setCurrentHand(hand);
      if (isFinished) {
        const handScore = decisionsToHandScore(hand.decisions);
        const finalHand = { ...hand, handScore };
        setHistory(e => [finalHand].concat(e).slice(0, 100))
        storeHand(finalHand);
        setSessionScore({ hands: sessionScore.hands + 1, totalScore: sessionScore.totalScore + handScore });
        if (config.autoNext === CONFIG_VALUE_ENABLED && handScore === 100) startNextHand();
      }
    } catch (err) {
      setHasErrored(true);
      handleError(new Error("Unable to generate hand score!"))
    } finally {
      setHandlingDecision(false);
    }
  }, [currentHand, setHistory, positions, sessionScore, rng, config.autoNext, config.rngScoring, handleError, setHasErrored, startNextHand, setData, setHandlingDecision, getAccessTokenSilently])

  useEffect(() => {
    if (playing && currentHand?.status === IN_PROGRESS_STATUS && !hasErrored) {
      // If the next player to act is hero, we should load the frequency data for them if enabled
      if (currentHand.nextToAct === currentHand.heroPosition) {
        getAccessTokenSilently()
        .then(APIClient)
        .then(client => client.getNodeData(
          currentHand.rangeSetData.id,
          currentHand.startPosition,
          gameTreePathToNodeId(trimGameTree(currentHand.gameTreePath)),
          currentHand.rangeSetData.version
        ))
        .then((nodeData) => {
          const pd = gameTreeToPotDetails(currentHand.gameTreePath, currentHand.rangeSetData)
          if (nodeData.terminal === true) return setData(e => ({ ...e, terminal: true, nextOptions: [] }));
          setData({
            ...nodeData,
            actions: nodeData?.nextOptions.length > 1 ? nodeData
              .nextOptions
              .map((action, idx, src) => {
                return {
                  id: action,
                  name: actionIdToString(action),
                  color: getActionColor(action, src),
                  size: actionInBB(action, pd)
                }
              }) : []
          })
        })
          .catch(err => {
            console.warn(err);
            setHasErrored(true);
            handleError(new Error("Unable to play hand!"))
          })
          .finally(() => setLoading(false));
      }
      // If the next player to act is not hero, load up there actions
      if (currentHand.nextToAct !== currentHand.heroPosition) {
        const order = orderOfAction(currentHand.gameTreePath, currentHand.startPosition, currentHand.rangeSetData.seats);
        const currentPath = gameTreePathToNodeId(currentHand.gameTreePath);
        const heroIndex = order.indexOf(currentHand.heroPosition);
        const nextUp = order.slice(0, heroIndex !== -1 ? heroIndex : order.length);
        getAccessTokenSilently()
          .then(APIClient)
          .then(client => client.getActions(
            currentHand.rangeSetData.id,
            currentHand.startPosition,
            currentHand.seats.filter(i => !i.sittingOut),
            nextUp.length,
            currentPath.replace(currentPath.split(currentHand.startPosition)[0], "")
          ))
          .then(resp => handleActionSelection(resp.actions.map(i => i.action)))
          .catch(err => {
            console.warn(err);
            setHasErrored(true);
            handleError(new Error("Unable play hand!"))
          })
      }
    }
  }, [currentHand, scenario, setData, setLoading, hasErrored, playing, handleActionSelection, handleError, getAccessTokenSilently]);

  const handFinished = (hand) => {
    const gameTreePath = hand.gameTreePath;
    const seats = hand.rangeSetData.seats;
    const heroPosition = hand.heroPosition;
    const startPosition = hand.startPosition;
    // Check if hero has folded or nobody is left to act
    return !isStillInHand(gameTreePath, heroPosition, startPosition, seats)
      || orderOfAction(gameTreePath, startPosition, seats).length === 0;
  }

  const handleHistorySelect = (hand) => {
    setCurrentHand(hand);
    setShowHistory(false);
  }

  useHotkeys('space,enter', () => {
    if (!loading) {
      if (!playing) {
        startPlay();
      } else if (playing && currentHand?.status === DONE_STATUS) {
        startNextHand();
      }
    }
  }, {keyup: true}, [playing, currentHand, scenario, loading, startNextHand, startPlay]);

  useHotkeys('1,2,3,4,5,6,7,8,9', (e, handler) => {
    if (!loading && !handlingDecision) {
      if (playing && currentHand?.status !== DONE_STATUS && data?.nextOptions && parseInt(handler.key) <= data?.nextOptions.length) {
        handleActionSelection([data.nextOptions[parseInt(handler.key) - 1]]);
      }
    }
  }, {keyup: true}, [playing, currentHand, data, scenario, loading, handleActionSelection, handlingDecision]);

  if (loading && !scenario.rangeSet && rangeSets.length === 0) return <LoadingPlaceholder />;

  if (!hasErrored && loadedRangeSets && rangeSets.length === 0) return <NoAccessAlert />;

  return (
    <div>
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className={classes.formControlInline}>
              <InputLabel id="range-set-label">Range Set</InputLabel>
              <Select
                labelId="range-set-label"
                id="range-set-select"
                value={scenario.rangeSet}
                placeholder="Select a scenario"
                onChange={e => handleScenarioChange({ rangeSet: e.target.value, positions: [] })}
                className={classes.select}
                disabled={playing || loading}
              >
                {rangeSets.map(rangeSet => <MenuItem key={rangeSet.id} value={rangeSet.id}>{rangeSet.name}</MenuItem>)}
              </Select>
              {scenario.rangeSet && !rangeSets.find(i => i.id === scenario.rangeSet).complete && <FormHelperText className={classes.rangeWarning}>Not all seats are playable for this range set which may result in some hands being automatically folded</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControlInline} style={{ paddingTop: compact ? 0 : 12 }}>
              <Button disabled={playing} variant="outlined" color="primary" onClick={() => setShowTrainerConfigDialog(e => !e)}><Settings /> Trainer Config</Button>
              <TrainerConfigDialog
                rangeSetData={rangeSets.find(i => i.id === scenario.rangeSet)}
                positions={scenario.positions}
                filters={filters}
                config={config}
                open={showTrainerConfigDialog}
                loading={loading}
                onClose={() => setShowTrainerConfigDialog(false)}
                onFilterChange={handleFilterChange}
                onConfigChange={handleConfigChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl className={classes.formControlInline} style={{ paddingTop: compact ? 0 : 12 }}>
              <Button disabled={loading} variant={!isDarkMode || (isDarkMode && !playing) ? "contained" : "outlined"} color={playing && !isDarkMode ? "secondary" : "primary"} onClick={handlePlayToggle}>
                {!playing ? <><PlayArrow /> Take Seat</> : <><Stop /> Stand Up</>}
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8} lg={8} xl={6}>
          <Box>
            <PokerTable
              hideFolded={currentHand?.status === IN_PROGRESS_STATUS}
              potDetails={currentHand ? gameTreeToPotDetails(currentHand.gameTreePath, currentHand.rangeSetData) : { pot: 0 }}
              atBottom={currentHand?.heroPosition}
              seats={currentHand?.seats.map(seat => ({
                ...seat,
                faceDown: !((currentHand.status === DONE_STATUS && config.showVillainCards === CONFIG_VALUE_ENABLED) || currentHand.heroPosition === seat.position),
              })) || []}
              gameTreePath={currentHand?.gameTreePath || []}
              dealerIndex={currentHand ? currentHand?.seats.findIndex((i, idx, src) => src.length > 2 ? i.position === "BU" : i.position === "SB") - currentHand?.seats.findIndex(i => i.position === currentHand.heroPosition) : 0}
              compact={compact} />
          </Box>
          {playing && currentHand && <Box mt={currentHand.status === DONE_STATUS ? 2 : 1} className={classes.optionsContainer}>
            {currentHand.status === DONE_STATUS && (config.autoNext !== CONFIG_VALUE_ENABLED || currentHand.decisions.some(i => i.score < 100)) && <Button onClick={startNextHand} size="large" variant="contained" color="primary" style={{ margin: "auto", display: "block" }}>Next Hand</Button>}
            {
              currentHand.status === IN_PROGRESS_STATUS && currentHand.nextToAct === currentHand.heroPosition && <>

                {rng && <Box mb={1} style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}>
                  <Typography className={classes.rng}>RNG: {rng}</Typography><Tooltip title="Use the RNG to select the optimal decision for combos with mixed strategies"><Info /></Tooltip>
                </Box>}
                <NextOptions
                  leftToAct={[]}
                  nextOptions={data.nextOptions?.map(i => [i]) || []}
                  actions={data.actions || []}
                  fullWidth={compact}
                  onChange={handleActionSelection}
                  size={"medium"}
                  buttonStyles={{ minWidth: compactButtons ? "48%" : "32%" }}
                />
              </>
            }
          </Box>}
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={4}>
          {
            !currentHand
              ? <Typography>Your hand evaluation will appear here once you start playing</Typography>
              : <div>
                <Box mb={1}>
                  <Typography>Session Score ({sessionScore?.hands || 0} hands)</Typography>
                  <EvaluationBar value={sessionScore.totalScore / sessionScore.hands || 0} />
                </Box>
                <Tabs
                  value={tab}
                  onChange={(e, val) => setTab(val)}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons={false}
                >
                  <Tab label="Evaluation" />
                  <Tab label="Game Tree" />
                  {config.answerMode === CONFIG_VALUE_ENABLED 
                    && playing && <Tab label="Decision Assistance" />}
                </Tabs>

                <TabPanel value={tab} index={0} dir={theme.direction}>
                  <HandEvaluation decisions={[...currentHand.decisions]} />
                </TabPanel>
                <TabPanel value={tab} index={1} dir={theme.direction}>
                  <GameTree
                    currentRangeSetDetails={currentHand?.rangeSetData}
                    gameTreePath={currentHand.gameTreePath}
                    nextOptions={currentHand.nextOptions}
                    showEllipsis={currentHand.status === IN_PROGRESS_STATUS}
                  />
                </TabPanel>
                {config.answerMode === CONFIG_VALUE_ENABLED && playing && <TabPanel value={tab} index={2} dir={theme.direction}>
                  {
                    currentHand.status === IN_PROGRESS_STATUS && currentHand.nextToAct === currentHand.heroPosition && Object.keys(data).length > 0
                      ? <Box>
                          <StrategyTable
                            percentages={comboFrequenciesToPercentages(data.frequency[cardsToComboString(getSeat(currentHand, currentHand.nextToAct).cards)])}
                            evData={data.EV[cardsToComboString(getSeat(currentHand, currentHand.nextToAct).cards)]}
                            actions={data.actions}
                          />
                        </Box>
                      : <Typography>The decision assistance will display the action frequencies for the current spot</Typography>
                  }
                </TabPanel>}
              </div>
          }
        </Grid>
      </Grid>
      <SwipeableDrawer onOpen={() => setShowHistory(true)} anchor={"right"} open={showHistory} onClose={() => setShowHistory(false)}>
        <HandHistory onClick={handleHistorySelect} history={history} />
      </SwipeableDrawer>
      <Fab disabled={playing} onClick={() => setShowHistory(true)} aria-label={"history"} className={classes.fab} color="primary">
        <Tooltip title="View Hand History" aria-label="history-button">
          <History />
        </Tooltip>
      </Fab>
    </div>
  );
}

const HandHistory = ({ history, onClick }) => {
  const classes = useStyles();
  return (<List
    className={classes.history}
    aria-labelledby="history-subheader"
    subheader={
      <ListSubheader component="div" id="history-subheader">
        Hand History
        </ListSubheader>
    }
  >
    {
      history.length === 0 && (
        <ListItem>
          Your hands will appear here as you play
        </ListItem>
      )
    }
    {
      history.map((hand, i) => (
        <ListItem key={i} button onClick={() => onClick(hand)}>
          <ListItemIcon style={{ minWidth: 90 }}>{
            getSeat(hand, hand.heroPosition).cards.map((j, cIdx) => (
              <Card
                key={cIdx}
                rank={j.rank}
                suit={j.suit}
                width={40}
              />))
          }</ListItemIcon>
          <ListItemText primary={hand.rangeSet} secondary={`Position: ${hand.heroPosition}`} />
          <ListItemSecondaryAction>
            <Score score={Math.round(hand.handScore)} />
          </ListItemSecondaryAction>
        </ListItem>
      ))
    }
  </List>
  )
}

const Score = ({ score, size = "small", title }) => {
  const classes = useStyles();
  return <div className={`${classes.score} ${classes[`${size}Score`]} ${score > 60 ? classes.optimal : classes.suboptimal}`}>
    {title && <Typography style={{ fontWeight: 800 }}>{title}</Typography>}
    <div>{score}</div>
  </div>
}

const TrainerConfigDialog = ({ rangeSetData, positions, config, filters = {}, onConfigChange, onFilterChange, onClose, open }) => {
  const classes = useStyles();

  const { presets = [], enabledForHero = {}, startPositions = {} } = filters;

  const handleStartPositionToggle = (e, position) => {
    const otherSeatUpdates = !e.target.checked
      ? rangeSetData
        .seats
        .slice(0, rangeSetData.seats.indexOf(position))
        .reduce((acc, next) => ({ ...acc, [next]: false }), {})
      : rangeSetData
        .seats
        .slice(rangeSetData.seats.indexOf(position))
        .reduce((acc, next) => ({ ...acc, [next]: true }), {})
      ;
    onFilterChange({
      ...filters,
      startPositions: {
        ...(startPositions || {}),
        ...otherSeatUpdates,
        [position]: e.target.checked,
      },
      enabledForHero: {
        ...(enabledForHero || {}),
        ...otherSeatUpdates,
        [position]: e.target.checked
      }
    })
  }

  const handleHeroPositionToggle = (e, position) => {
    onFilterChange({
      ...filters,
      enabledForHero: {
        ...(enabledForHero || {}),
        [position]: e.target.checked
      }
    })
  }

  const handlePresetSelect = (e, newPresets) => {
    if (!newPresets || newPresets.length === 0) {
      onFilterChange({
        presets: [],
        startNodes: [],
        startPositions,
        enabledForHero
      })
    } else {
      const allPositions = Object.keys(startPositions);
      const enabledFrom = Math.min(...newPresets.flatMap(preset => preset.startNodes.map(i => allPositions.indexOf(startPositionFromNodeId(i, rangeSetData.seats)))));
      const presetHeroPositions = newPresets.flatMap(preset => preset.enabledForHero);
      onConfigChange("villainRanges", "100");
      onFilterChange({
        ...filters,
        presets: newPresets,
        startPositions: rangeSetData.seats.reduce((acc, i) => ({
          ...acc,
          [i]: allPositions.indexOf(i) >= enabledFrom
        }), {}),
        enabledForHero: rangeSetData.seats.reduce((acc, i) => ({
          ...acc,
          [i]: presetHeroPositions.indexOf(i) !== -1
        }), {})
      })
    }
  }

  const toggleConfigSwitch = (key) => (e) => onConfigChange(key, e.target.checked ? CONFIG_VALUE_ENABLED : CONFIG_VALUE_DISABLED);

  const handleSliderChange = (key) => (e, newValue) => onConfigChange(key, newValue);

  const canBeSeated = (i) => rangeSetData.seats.indexOf(i) >= rangeSetData.seats.indexOf(positions[0]);

  const isSeatToggleDisabled = (position) => {
    // Disable if we're using specific start nodes
    if (presets.length > 0) return true;
    // At least 1 seat WITH AN OPEN RANGE must be enabled
    const currentlyEnabled = Object.keys(startPositions || {}).filter(i => startPositions[i]);
    const enabledStartPositions = positions.filter(i => currentlyEnabled.includes(i));
    return (currentlyEnabled.length === 1 && position === currentlyEnabled[0])
      || (enabledStartPositions.length === 1 && currentlyEnabled.includes(position))
    ;
  }

  const isHeroToggleDisabled = (position) => {
    // Disable if we're using specific start nodes
    if (presets.length > 0) return true;
    // Blinds must be enabled and start position must be valid
    const currentlyEnabled = Object.keys(enabledForHero || {}).filter(i => enabledForHero[i]);
    return ((!["BB", "SB", "ST", "S1", "S2"].includes(position)) && !startPositions[position])
      // At least one position must be enabled for hero
      || (currentlyEnabled.length === 1 && position === currentlyEnabled[0]);
  }

  const availablePresets = (allPresets[rangeSetData?.id] || []).filter(i => i.startNodes.every(j => positions.indexOf(startPositionFromNodeId(j)) !== -1));

  return (
    <Dialog maxWidth="sm" fullWidth={true} onClose={onClose} aria-labelledby="dialog-title" open={open}>
      <DialogTitle id="settings-dialog-title">Trainer Config</DialogTitle>
      <div className={classes.dialogBody}>
        {
          rangeSetData
            ? <>
              <Box mb={1}>
                <FormControlLabel
                  control={<Switch size="small" checked={config.answerMode === CONFIG_VALUE_ENABLED} onChange={toggleConfigSwitch(CONFIG_KEYS.answerMode)} />}
                  label="Enable Decision Assistance"
                  className={classes.formControl}
                />
              </Box>
              <Box mb={1}>
                <FormControlLabel
                  control={<Switch size="small" checked={config.rngScoring === CONFIG_VALUE_ENABLED} onChange={toggleConfigSwitch(CONFIG_KEYS.rngScoring)} />}
                  label="RNG Based Scoring"
                  className={classes.formControl}
                />
              </Box>
              <Box mb={1}>
                <FormControlLabel
                  control={<Switch size="small" checked={config.showVillainCards === CONFIG_VALUE_ENABLED} onChange={toggleConfigSwitch(CONFIG_KEYS.showVillainCards)} />}
                  label="Show Villain Cards"
                  className={classes.formControl}
                />
              </Box>
              <Box mb={1}>
                <FormControlLabel
                  control={<Switch size="small" checked={config.autoNext === CONFIG_VALUE_ENABLED} onChange={toggleConfigSwitch(CONFIG_KEYS.autoNext)} />}
                  label="Automatically Move to Next Hand"
                  className={classes.formControl}
                />
              </Box>
              {
                availablePresets.length > 0 && <Box mb={1}>
                  <FormControl className={classes.formControl}>
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      options={availablePresets}
                      getOptionLabel={(option) => option?.title || ""}
                      onChange={handlePresetSelect}
                      filterSelectedOptions
                      value={presets}
                      defaultValue={null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          variant="outlined"
                          label="Filter by Spot"
                          placeholder="Filter by Spot"
                        />
                      )}
                    />
                  </FormControl>
                  {presets.length > 0 && <Alert severity="info">When filtering by spot, villain ranges and seat filters are predetermined</Alert>}
                </Box>
              }
              <Box mb={1}>
                <Typography gutterBottom>
                  Hero Range
                </Typography>
                <FormHelperText>
                  Narrow hero's range to the top x% of starting hands
                </FormHelperText>
                <Box pl={1} pr={1}>
                  <Slider
                    value={Number(config.heroRange)}
                    onChange={handleSliderChange("heroRange")}
                    valueLabelDisplay="auto"
                    valueLabelFormat={x => `${x}%`}
                    step={5}
                    min={5}
                    marks={[
                      {value: 5, label: "5%"},
                      {value: 25, label: "25%"},
                      {value: 50, label: "50%"},
                      {value: 75, label: "75%"},
                      {value: 100, label: "100%"}
                    ]}
                  />
                </Box>
              </Box>
              <Box mb={1}>
                <Typography gutterBottom>
                  Villain Ranges
                </Typography>
                <FormHelperText>
                  Narrow all villain ranges to the top x% of starting hands
                </FormHelperText>
                <Box pl={1} pr={1}>
                  <Slider
                    value={Number(config.villainRanges)}
                    onChange={handleSliderChange("villainRanges")}
                    valueLabelDisplay="auto"
                    valueLabelFormat={x => `${x}%`}
                    disabled={presets.length > 0}
                    step={5}
                    min={5}
                    marks={[
                      {value: 5, label: "5%"},
                      {value: 25, label: "25%"},
                      {value: 50, label: "50%"},
                      {value: 75, label: "75%"},
                      {value: 100, label: "100%"}
                    ]}
                  />
                </Box>
              </Box>
              <Box mb={1}>
                <TableContainer>
                  <Table size="small" className={classes.table} aria-label="strategy table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Position</TableCell>
                        <TableCell align="center">Seated</TableCell>
                        <TableCell align="center">Enabled for Hero</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rangeSetData?.seats.map((i) => (
                        <TableRow key={i}>
                          <TableCell component="th" scope="row" align="left">
                            {i}
                          </TableCell>
                          <TableCell align="center">
                            {
                              canBeSeated(i) && !["BB", "SB"].includes(i) && !i.startsWith("S")
                                ? <Switch
                                  disabled={isSeatToggleDisabled(i)}
                                  checked={startPositions[i]}
                                  onChange={(e) => handleStartPositionToggle(e, i)}
                                />
                                : "N/A"
                            }
                          </TableCell>

                          <TableCell align="center">
                            {
                              canBeSeated(i)
                                ? <Switch
                                  disabled={isHeroToggleDisabled(i)}
                                  checked={enabledForHero[i]}
                                  onChange={(e) => handleHeroPositionToggle(e, i)}
                                />
                                : "N/A"
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

            </>
            : <CircularProgress />
        }
      </div>
    </Dialog>
  )
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={1} mt={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default Trainer;
